// import EditionCard from "@app/components/EditionCard";

function Edition() {
  return (
    <section className="!pb-0">
      <div className="container text-center">
        <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold">
          Édition{" "}
          <span className="text-red-main">{new Date().getFullYear()}</span>
        </h2>
        <h3 className="uppercase font-bold py-2 leading-5 md:py-4 text-sm md:text-base openSans">
          CONCOURS NATIONAL DU <br /> MEILLEUR JEUNE DIRIGEANT DE L&apos;ANNÉE
        </h3>
      </div>
      <div className="container w-full flex flex-col lg:flex-row py-10 gap-10">
        {/* <EditionCard title={"this is a title"} children={<p>here are the children</p>} />
        <EditionCard title={"this is a title"} />
        <EditionCard title={"this is a title"} /> */}

        {/* Card 1 */}
        <div className="w-full lg:w-1/3">
          <h4 className="relative pb-1 text-lg after:content-[''] after:h-1 after:w-28 after:bg-red-main after:absolute after:bottom-0 after:left-0">
            CJD Business Awards
          </h4>
          <div className="py-5 grid gap-4">
            <p>
              C&apos;est un concours organisé depuis 2008 par le Centre des
              Jeunes Dirigeants d&apos;Entreprises de Tunisie, il a pour
              objectif de récompenser des jeunes dirigeants tunisiens ayant fait
              preuve de leadership dans le développement de leur entreprise, de
              valoriser dans les parcours le sens de l&apos;initiative et
              l&apos;originalité des projets et de promouvoir l&apos;innovation
              et l&apos;entrepreneuriat.
            </p>

            <p>
              Les CJD Business Awards permettent aux jeunes lauréats de
              bénéficier d&apos;une forte médiatisation sur la scène économique,
              d&apos;un accompagnement et de connexions aux réseaux du CJD et
              ceux de ses partenaires.
            </p>
          </div>
        </div>

        {/* Card 2 */}
        <div className="w-full lg:w-1/3">
          <h4 className="relative pb-1 text-lg after:content-[''] after:h-1 after:w-28 after:bg-red-main after:absolute after:bottom-0 after:left-0">
            CJD Business Awards
          </h4>
          <div className="py-5 grid gap-4">
            <p>
              Les conditions d&apos;éligibilité pour les jeunes dirigeants
              désirant participer au CJD Business Awards sont les suivants :
            </p>
            <ul className="list-disc pl-7">
              <li>Avoir au maximum 50 ans.</li>
              <li>
                Etre dirigeant d&apos;une société établie en Tunisie ayant au
                moins 1 année d&apos;exploitation.
              </li>
              <li>
                Avoir réussi à développer un modèle économique performant et
                innovant (développement d&apos;affaires, expansion régionale,
                internationale, création d&apos;emplois, prise en compte des
                facteurs d&apos;innovation, économiques, sociétaux et
                environnementaux).
              </li>
            </ul>
          </div>
        </div>

        {/* Card 3 */}
        <div className="w-full lg:w-1/3">
          <h4 className="relative pb-1 text-lg after:content-[''] after:h-1 after:w-28 after:bg-red-main after:absolute after:bottom-0 after:left-0">
            CJD Business Awards
          </h4>
          <div className="py-5 grid gap-4">
            <p>
              Le jury sera composé d&apos;Experts financier, de Consultants en
              management, de chefs d&apos;entreprise, de journalistes, de
              représentants d&apos;ONG, d&apos;experts membres du CJD et
              d&apos;un ancien lauréat.
            </p>

            <p>
              Le CJD Tunisie travaille dans le respect le plus absolu de la
              confidentialité et du caractère anonyme des réponses délivrées.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Edition;
