import Image from "next/future/image";

interface SponsorsUIProps {
  title: string;
  src: string;
  height: number;
  width: number;
  alt: string;
  className?: string;
}
function SponsorsUI(props: SponsorsUIProps) {
  const { title, src, height, width, alt } = props;
  return (
    <div className="text-center py-4 md:mb-16">
      <h4 className="text-lg md:text-xl mb-4 md:mb-16">{title}</h4>
      <div className="max-w-xl mx-auto">
        <Image src={src} alt={alt} width={width} height={height} />
      </div>
    </div>
  );
}

export default SponsorsUI;
