import WinnersCard from "@app/components/WinnersCard";
import data from "../../../../json/data.json";
export interface winnersDataProps {
  firstName: string;
  lastName: string;
  business: string;
  year: number;
  href: string;
}


function Winners() {
  const lastThreeYears = Object.keys(data).sort((a, b) => parseInt(b) - parseInt(a)).slice(0, 3);
  const winnersData = lastThreeYears.map((year) => ({
    name: data[year].winner.name,
    business: data[year].winner.business,
    year: parseInt(year),
    href: `/laureats/annee-${year}`,
  }));
  
  return (
    <section className="!pb-0">
      <div className="container w-full xl:flex xl:gap-6">
        <div className="w-full xl:w-1/5">
          <h2 className="openSans font-bold text-center xl:text-right mb-16">
            Célébrant les <br className="hidden lg:block" /> entrepreneurs
            depuis <br />
            <span className="Saira text-red-main text-5xl lg:text-7xl">
              2008
            </span>
          </h2>
        </div>
        <div className="w-full xl:w-4/5 flex flex-col md:flex-row items-center gap-4">
          {winnersData.map((winner, key) => (
            <WinnersCard winner={winner} key={key} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Winners;
