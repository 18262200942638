import SponsorsUI from "@app/components/SponsorsUI";
import Image from "next/future/image";

function Sponsors() {
  return (
    <section className="!pb-0">
      <div className="container">
        <div className="text-center py-4 md:mb-16">
          <h4 className="text-lg md:text-xl mb-4 md:mb-16">Organisé par</h4>
          <div className="flex flex-col md:flex-row justify-center items-center gap-8 mx-auto">
            <Image
              src="/assets/images/cjd.jpg"
              alt="CJD Tunisie"
              width={300}
              height={172}
            />
            <Image
              src="/assets/images/kas.jpg"
              alt="KAS"
              width={300}
              height={172}
            />
          </div>
        </div>
        <SponsorsUI
          title="Sponsors du CJD Tunisie"
          src="/assets/images/sponsors-logo-2023.png"
          alt="la liste des sponsors: Tunisie Telecom, Carte Assurances, Nety, MS Solutions, Skywork, Tekru Technologies"
          width={800}
          height={270}
        />
        <div className="text-center py-4 md:mb-16">
          <h4 className="text-lg md:text-xl mb-4 md:mb-16">Partenaire média</h4>
          <div className="flex justify-center gap-8">
            <Image
              src="/assets/images/entreprise-magazine.png"
              alt="Entreprise Magazine"
              width={150}
              height={80}
            />
            <Image
              src="/assets/images/tuniscope.png"
              alt="Tuniscope"
              width={150}
              height={80}
            />
            <Image
              src="/assets/images/express-fm.png"
              alt="Express FM"
              width={150}
              height={80}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sponsors;
