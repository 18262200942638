const sConfig = {
  lang: "fr",
  separator: "|",
  title: "CJD Business Awards",
  description:
    "Concours organisé depuis 2008 par le CJD Tunisie pour récompenser des jeunes dirigeants tunisiens ayant fait preuve de leadership.",
  keywords: "CJD Tunisie, CJD, CJD Business Awards, Tunisie, leadership",
  defaultSocialImage: "/assets/images/social-2024.png",
  organization: {
    name: "CJD Tunisie",
    website: "http://www.cjd.tn/",
    logo: "/favicons/android-chrome-512x512.png",
    mainEmail: "permanence@cjd.tn",
    mainTel: "+216 70 294 959",
    socials: ["https://www.facebook.com/cjdtn/"],
  },
  numbers: {
    type: "tel",
    name: "Tel",
    number: "+216 70 294 959",
    href: "tel:+21670294959",
  },
  addresses: [
    {
      name: "Tunis-Aouina",
      link: "https://g.page/cjdtn",
      street: "Immeuble Yasmine, rue du Lac Malaren",
      city: "Les Berges du Lac",
      state: "Tunis",
      country: "Tunisie",
      zip: "1053",
      tel: "+216 70 294 959",
    },
  ],
};

export default sConfig;
