import { StaticImageData } from "next/future/image";
import Image from "next/image";

interface GalleryImageProps {
  src: StaticImageData;
}

function GalleryImage(props: GalleryImageProps) {
  const { src } = props;
  return (
    <div>
      <Image
        src={src}
        placeholder="blur"
        alt="image gallerie"
        width={327}
        height={327}
        priority={true}
      />
    </div>
  );
}

export default GalleryImage;
