import GalleryImage from "./GalleryImage";
import image1 from "../../../public/assets/images/gallery/image1.png";
import image2 from "../../../public/assets/images/gallery/image2.png";
import image3 from "../../../public/assets/images/gallery/image3.png";
import image4 from "../../../public/assets/images/gallery/image4.png";
import image5 from "../../../public/assets/images/gallery/image5.png";
import image6 from "../../../public/assets/images/gallery/image6.png";
import image7 from "../../../public/assets/images/gallery/image7.png";
import image8 from "../../../public/assets/images/gallery/image8.png";

const data = [image1, image2, image3, image4, image5, image6, image7, image8];
function Gallery() {
  return (
    <section>
      <div className="container grid grid-cols-4 gap-1 md:gap-4">
        {data.map((el, key) => {
          return <GalleryImage src={el} key={key} />;
        })}
      </div>
    </section>
  );
}

export default Gallery;
