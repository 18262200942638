import Image from "next/future/image";
import { useRouter } from "next/router";
import TrophyWinners from "../../../public/assets/images/winners-trophy.png";

function WinnersCard({ winner }) {
  const router = useRouter();
  return (
    <div className="w-[300px] max-w-[300px] bg-grey-light pt-5 mb-12 px-5 relative">
      <div className="h-32 w-1/2 md:w-full xl:w-1/2">
        <h4 className="text-2xl openSans leading-7 font-bold">{winner.name}</h4>
        <p className="text-gray-600 italic text-sm py-3 leading-4">
          {winner.business}
        </p>
      </div>
      <div className="absolute md:hidden xl:block -top-9 right-0 sm:right-8">
        <Image
          src={TrophyWinners}
          width={130}
          height={193}
          alt="logo trophy of winners"
        />
      </div>
      <div className="text-center">
        <a
          className="text-white text-sm bg-red-main hover:bg-red-strong transition-color px-2 py-3 font-semibold flex justify-center cursor-pointer"
          onClick={() => router.push(`${winner.href}`)}
        >
          Meilleur Jeune Dirigeant {winner.year}
        </a>
      </div>
    </div>
  );
}

export default WinnersCard;
