import HomeHero from "../src/sections/home/HomeHero";
import Head from "../src/components/Head/Head";
import Edition from "src/sections/home/Edition";
import Sponsors from "src/sections/home/Sponsors";
import Gallery from "@app/components/Gallery";
import Winners from "src/sections/home/Winners";
import Form from "src/sections/home/Form";
import { NotificationsProvider } from "@mantine/notifications";
import {
  getSubmissionStatus,
  SubmissionStatus,
} from "src/helpers/submissionStatus";

interface HomeProps {
  submissionStatus: SubmissionStatus;
}

function Home(props: HomeProps) {
  return (
    <>
      <NotificationsProvider>
        <Head />
        <HomeHero isOpen={props.submissionStatus.isOpen} />
        <Winners />
        <Edition />
        <Form isOpen={props.submissionStatus.isOpen} />
        <Sponsors />
        <Gallery />
      </NotificationsProvider>
    </>
  );
}

export async function getServerSideProps() {
  return {
    props: {
      submissionStatus: await getSubmissionStatus(),
    },
  };
}

export default Home;
