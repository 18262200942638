import Head from "next/head";
import Script from "next/script";
import sConfig from "../../siteConfig";
import exiryConfig from "../../../exiry.config";
import { ReactNode } from "react";
import urlJoin from "url-join";
import { useRouter } from "next/router";

const siteUrl = process.env.NEXT_PUBLIC_PUBLIC_URL;

export function gen_OrganizationLdJson() {
  const ldJsonOrg = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: sConfig.organization.website || siteUrl,
    name: sConfig.organization.name,
    logo: urlJoin(siteUrl, sConfig.organization.logo),
    email: ["permanence@cjd.tn"],
    foundingDate: "1998",
    telephone: "+216 70 294 959",
    sameAs: [...sConfig.organization.socials],
    address: [],
  };
  if (Array.isArray(sConfig.addresses)) {
    ldJsonOrg.address = sConfig.addresses.map((address) => ({
      "@type": "PostalAddress",
      addressLocality: [
        address.street,
        address.city,
        address.state,
        address.country,
        ,
        address.zip,
      ]
        .filter((e) => !!e)
        .join(", "),
      postalCode: address.zip || "",
      streetAddress: address.street || "",
    }));
  }
  return ldJsonOrg;
}

interface HeadImage {
  src: string;
  height: number;
  width: number;
}

export interface HeaderOption {
  title?: string;
  description?: string;
  image?: string | HeadImage;
  imageAddPath?: boolean;
  keywords?: string;
  disableOpenGraph?: boolean;
  noTitleSuffix?: boolean;
}

export interface HeaderProps {
  options?: HeaderOption;
  children?: ReactNode;
}

function Header(props: HeaderProps) {
  const options = props.options || {};

  const { asPath } = useRouter();
  const currentUrl = urlJoin(siteUrl, asPath);
  // Get the title and add the suffix if not the site tile
  let title = options.title || sConfig.title;
  if (!options.noTitleSuffix && title !== sConfig.title) {
    title += " " + sConfig.separator + " " + sConfig.title;
  }

  const description = options.description || sConfig.description;

  const defaultImage = sConfig.defaultSocialImage;
  options.imageAddPath = options.imageAddPath !== false;

  let image = defaultImage;
  if (options.image) {
    if (typeof options.image === "string") {
      image = options.image;
    } else if (options.image?.src) {
      image = options.image.src;
    }
  }
  if (options.imageAddPath) {
    image = urlJoin(siteUrl, image);
  }

  const ldJsonOrg = gen_OrganizationLdJson();

  const ldJsonSite = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: siteUrl,
    name: sConfig.title,
    description: sConfig.description,
    author: ldJsonOrg,
    creator: ldJsonOrg,
  };
  /*potentialAction: {
    "@type": "SearchAction",
    target: process.env.NEXT_PUBLIC_SITE_URL + "/search/{search_term_string}/",
    "query-input": "required name=search_term_string",
  },*/

  const render_gTag =
    exiryConfig.GA_TrackingId && process.env.NODE_ENV === "production";

  const render_hotjar =
    exiryConfig.hotjarId && process.env.NODE_ENV === "production";

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content={options.keywords || exiryConfig.keywords}
        />
        {exiryConfig.googleSiteVerification && (
          <meta
            name="google-site-verification"
            content={exiryConfig.googleSiteVerification}
          />
        )}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={urlJoin(siteUrl, "/favicons/apple-touch-icon.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={urlJoin(siteUrl, "/favicons/favicon-32x32.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={urlJoin(siteUrl, "/favicons/favicon-16x16.png")}
        />
        <link
          rel="manifest"
          href={urlJoin(siteUrl, "/favicons/site.webmanifest")}
        />
        <link
          rel="mask-icon"
          href={urlJoin(siteUrl, "/favicons/safari-pinned-tab.svg")}
          color={exiryConfig.headerColor}
        />
        <link
          rel="shortcut icon"
          href={urlJoin(siteUrl, "/favicons/favicon.ico")}
        />
        <meta name="apple-mobile-web-app-title" content={sConfig.title} />
        <meta name="application-name" content={sConfig.title} />
        <meta
          name="msapplication-TileColor"
          content={exiryConfig.headerColor}
        />
        <meta
          name="msapplication-config"
          content="/favicons/browsersConfig.xml"
        />
        <meta name="theme-color" content={exiryConfig.headerColor} />

        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, shrink-to-fit=no"
        />

        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />

        <meta name="referrer" content="no-referrer-when-downgrade" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        {!options.disableOpenGraph && (
          <>
            <meta property="og:site_name" content={exiryConfig.title} />
            <meta property="og:image" content={image} />
            <meta property="og:image:width" content="1714" />
            <meta property="og:image:height" content="900" />
            <meta property="og:locale" content="fr_TN" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={currentUrl} />
          </>
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:image:width" content="1714" />
        <meta name="twitter:image:height" content="900" />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonSite) }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJsonOrg) }}
        />
        {props.children}
      </Head>
      {render_gTag && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${exiryConfig.GA_TrackingId}`}
          />
          <Script
            id=""
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${exiryConfig.GA_TrackingId}', {
                  page_path: window.location.pathname,
                  cookie_flags: "SameSite=None;Secure",
                });
                gtag('set', 'language', 'fr-TN');
              `,
            }}
          />
        </>
      )}
      {render_hotjar && (
        <Script
          id=""
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${exiryConfig.hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}
    </>
  );
}

export default Header;
