import Image from "next/image";
import { useAuthContext } from "src/context/AuthContext";
import FormTrophy from "../../../../public/assets/images/form-trophy.png";
import Form from "./Form";

interface FormLayoutProps {
  isOpen: boolean;
}

function FormLayout(props: FormLayoutProps) {
  const { user } = useAuthContext();

  if (user) return null;

  return (
    <section className="!pb-0" id="sign-up">
      <div className="!pt-0 py-10 container">
        <div className="bg-grey-bg pt-10 md:pb-10 relative overflow-hidden">
          <div className="w-full md:w-2/3 lg:w-1/2">
            {props.isOpen ? (
              <div className="p-6">
                <p className="font-bold Saira text-3xl">Inscrivez-vous</p>
                <p className="font-normal Saira text-2xl pb-6">
                  et soumissionnez
                </p>
                <Form />
              </div>
            ) : (
              <div className="px-6 pt-32 pb-40">
                <p className="font-normal Saira text-2xl">
                  L&apos;acceptation des soumissions{" "}
                </p>
                <p className="font-bold Saira text-3xl pb-6">
                  est clôturé pour cette année.
                </p>
                <p>Bonne chance aux candidats.</p>
              </div>
            )}
          </div>
          <div className="w-full mt-16 md:mt-0 md:absolute md:left-2/3 lg:left-1/2 md:w-2/3 lg:w-1/2 top-0 h-[300px] md:h-full">
            <div className="relative w-full h-full">
              <Image
                src={FormTrophy}
                placeholder="blur"
                alt="trophy form section"
                // width={755}
                // height={1066}
                layout="fill"
                objectFit="cover"
                className=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FormLayout;
