import { useForm } from "@mantine/form";
import { TextInput, Button, Radio, Input } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { PasswordInput } from "@mantine/core";
import { ApolloError, gql, useMutation } from "@apollo/client";
import { showNotification } from "@mantine/notifications";
import { useAuthContext } from "src/context/AuthContext";
import { loginDataFields } from "src/query/login";
import { useRouter } from "next/router";
import format from "date-fns/format";
import { differenceInYears } from "date-fns";
import "dayjs/locale/fr";
import InputMask from "react-input-mask";

const SIGN_UP_GQL = gql`
  ${loginDataFields}
  mutation SignUpBusiness($input: SignupBusinessInput!) {
    signupBusiness(input: $input) {
      ...LoginDataFields
    }
  }
`;

export default function Form() {
  const router = useRouter();
  const { handleLogin } = useAuthContext();

  const required = (value: string) =>
    !value || value.length < 2 ? "Ce champ est obligatoire" : null;

  const validateDate = (value: string) => {
    const age = differenceInYears(new Date(), new Date(value));
    const has18Years = age >= 18;

    return !has18Years ? "Vous devez avoir au minimum 18 ans" : null;
  };

  const form = useForm({
    initialValues: {
      civility: "MS",
      firstName: "",
      lastName: "",
      title: "",
      companyName: "",
      training: "",
      phoneNumber: "",
      email: "",
      birthday: "",
      password: "",
    },
    validate: {
      civility: (value) =>
        value === "MR" || value === "MS" ? null : "Ce champs est obligatoire",
      firstName: required,
      lastName: required,
      title: required,
      birthday: validateDate,
      companyName: required,
      training: required,
      phoneNumber: required,
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Email obligatoire"),
      password: required,
    },
  });

  const [mutate, { loading }] = useMutation(SIGN_UP_GQL);

  const handleSignUp = (model: typeof form.values) => {
    const data = {
      ...model,
      birthday: format(new Date(model.birthday), "yyyy-MM-dd"),
    };
    mutate({ variables: { input: data } })
      .then(({ data }) => {
        const { token, user } = data.signupBusiness;
        handleLogin(token, user);
        // Redirect
        router.push("/user/my-company/");
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.error(error);
        }
        if (error instanceof ApolloError) {
          const code = error.graphQLErrors?.[0]?.extensions?.code;
          if (code === "EMAIL_EXISTS") {
            form.setErrors({
              email: "Votre adresse email existe déjà, merci de vous connecter",
            });
            return;
          }
        }
        showNotification({
          radius: "md",
          color: "red",
          title: "Erreur au serveur",
          message:
            "Il y a eu un souci lors de traitement de votre demande, merci de ressayer plus tard. Notre équipe technique est notifiée et regardera le problème.",
        });
      });
  };

  return (
    <form
      onSubmit={form.onSubmit((model) => handleSignUp(model))}
      className="space-y-4"
    >
      <Radio.Group
        label="Civilité"
        withAsterisk
        {...form.getInputProps("civility")}
      >
        <Radio value="MS" label="Mme" />
        <Radio value="MR" label="Mr" />
      </Radio.Group>
      <TextInput
        label="Prénom"
        withAsterisk
        {...form.getInputProps("firstName")}
      />
      <TextInput
        label="Nom de famille"
        withAsterisk
        {...form.getInputProps("lastName")}
      />
      <DatePicker
        locale="fr"
        label="Date de naissance"
        withAsterisk
        inputFormat="DD/MM/YYYY"
        labelFormat="MM/YYYY"
        {...form.getInputProps("birthday")}
      />
      <TextInput label="Titre" withAsterisk {...form.getInputProps("title")} />
      <TextInput
        label="Société / raison sociale"
        withAsterisk
        {...form.getInputProps("companyName")}
      />
      <TextInput
        label="Formation"
        withAsterisk
        {...form.getInputProps("training")}
      />
      <Input.Wrapper id="phone-number-input" label="Numéro mobile" withAsterisk>
        <Input
          component={InputMask}
          mask="99 999 999"
          id="phone-number-input"
          placeholder="98 123 123"
          type="tel"
          {...form.getInputProps("phoneNumber")}
        />
      </Input.Wrapper>
      <TextInput label="Email" withAsterisk {...form.getInputProps("email")} />
      <PasswordInput
        label="Mot de passe"
        withAsterisk
        {...form.getInputProps("password")}
      />
      <Button
        type="submit"
        loading={loading}
        className="bg-green-main hover:bg-green-hover transition-colors w-full"
      >
        Inscription
      </Button>
    </form>
  );
}
