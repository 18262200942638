import Image from "next/future/image";
import Button from "../../../components/Button";
import logoHero from "../../../../public/assets/images/logo-hero.png";
import peopleHero from "../../../../public/assets/images/people-hero.png";
import { useAuthContext } from "src/context/AuthContext";

interface HomeHeroProps {
  isOpen: boolean;
}

function HomeHero(props: HomeHeroProps) {
  const { user } = useAuthContext();

  return (
    <section className="overflow-hidden relative w-full bg-grey-bg after:content-[url('/assets/images/hero-flag.png')] after:absolute after:bottom-[-2%] after:left-[80%] !p-0">
      <div className="container mx-auto h-full table items-center">
        <div className="table-cell w-full md:w-1/2 py-8 md:py-4 lg:py-8">
          <Image
            src={logoHero}
            placeholder="blur"
            alt="Logo du CJD Business Awards"
            width={513}
            height={264}
            className="w-[70%]"
            priority={true}
          />
          <div className="py-6 lg:max-w-[600px]">
            <p className="py-2 font-bold max-w-xl">Seriez-vous le prochain</p>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-medium leading-tight">
              Meilleur Jeune Dirigeant de l’année 2023
            </h1>
          </div>
          {user ? (
            <Button
              href="/user/my-application/"
              className="py-7 px-8 text-center"
            >
              <span className="font-light text-xs leading-4">
                Suivre ma <br />
                <span className="font-extrabold text-base">Candidature</span>
              </span>
            </Button>
          ) : props.isOpen ? (
            <Button href="/#sign-up" className="py-7 px-8 text-center">
              <span className="font-light text-xs leading-4">
                Tentez votre chance <br />
                <span className="font-extrabold text-base">Soumissionnez</span>
              </span>
            </Button>
          ) : (
            <div className="py-0 text-xs text-gray-500">
              L&apos;acceptation des soumissions est clôturé pour cette année.
            </div>
          )}
        </div>
        <div className="w-full md:w-1/2 hidden md:table-cell md:justify-center relative py-4">
          <Image
            src="/assets/images/hero-trophy.png"
            alt="Hero trophy background"
            className="absolute h-[90%] w-auto"
            width={429}
            height={514}
          />
          <Image
            src={peopleHero}
            placeholder="blur"
            alt="Une femme et homme pour le CJD Business Awards"
            width={513}
            height={264}
            className="md:w-[75%] lg:w-[55%] absolute bottom-[2rem]"
          />
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
